// CLASES DE UTILIDADES
//BOTON- Se pidio estandarizar los botones a uno diferente al que habiamos hecho, es mas simple.
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include font-size(18px);
  font-family: $Fuente1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 6px 12px;
  margin-bottom: 0;
  color: $color-claro;
  background-color: $color-secundario;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;

  //Para dispositivos CON posibilidad de hover
  @media (hover: hover) {
    &:active {
      color: $color-claro;
      text-decoration: none;
      transform: scale(0.95);
    }
    &:hover {
      color: $color-claro;
      text-decoration: none;
      background-color: get-opacity($color-secundario, 0.6);
    }
  }
  //Para dispositivos SIN posibilidad de hover
  /* 
    [ EFECTO RIPPLE, para mostrar al usuario que se esta interactuando con el boton y no usar un 
        estado :hover el cual se queda una vez que se apreto y no se va como en desktop ]
    */
  @media (hover: none) {
    background-position: center;
    transition: background 0.5s;

    &:hover {
      color: $color-claro;
      text-decoration: none;
      background: $color-secundario
        radial-gradient(circle, transparent 1%, $color-secundario 1%)
        center/15000%;
    }
    &:active {
      color: $color-claro;
      text-decoration: none;
      background-color: get-opacity($color-secundario, 0.4);
      background-size: 100%;
      transition: background 0s;
    }
  }
}
