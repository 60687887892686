::-webkit-scrollbar {
  width: 5px;
}

::selection {
  background-color: get-opacity($color-oscuro, 0.8);
  color: $color-claro;
}

::-webkit-scrollbar-track {
  background: $color-primario;
}

::-webkit-scrollbar-thumb {
  background: get-opacity($color-secundario, 0.9);
}

::-webkit-scrollbar-thumb:hover {
  background: $color-secundario;
}

// variables css
:root {
  --main-font-size: 14px;
}

@include media(desktop-s) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  :root {
    --main-font-size: 13px;
  }
}

@include media(desktop-m) {
  :root {
    --main-font-size: 14px;
  }
}

@include media(desktop-ml) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-l) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-xl) {
  :root {
    --main-font-size: 16px;
  }
}
@include media(desktop-xxxl) {
  :root {
    --main-font-size: 22px;
  }
}

html,
body {
  font-size: var(--main-font-size);
  font-family: $Fuente1 !important;
  color: $color-oscuro;
  background-color: $color-claro;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @include media(desktop-m) {
  }
}
