.footer {
  position: relative;
  z-index: 96;
  width: 100%;
  background-color: $color-oscuro;

  @include media(desktop-m) {
    background-color: $color-oscuro;
  }
}

.footer__container {
  padding: 50px $space_x2;
  margin: 0 auto;
  grid-row-gap: $space_x2;
  width: 100%;
  color: $color-claro;
  @include font-size(14px);
  @include display-grid;
  @include grid-template-columns(auto);

  &__column1 {
    img {
      margin-bottom: $space_x3;
      width: 130px;
      height: auto;
    }

    p,
    a {
      margin-bottom: $space_x2;
      display: flex;
      font-weight: 200;
      letter-spacing: 0.28px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        display: flex;
        align-items: center;
        margin-bottom: $space_x2;
        text-transform: none;
        text-decoration: none;
        color: $color-claro;

        img {
          margin: 0px $space 0 0;
          width: 11px;
          height: 100%;
        }

        &:hover {
          color: $color-secundario;
        }
      }
    }

    &__social {
      margin-top: $space_x3;
      max-width: 180px;

      a {
        transform: scale(0.8);

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }

  &__column2,
  &__column3 {
    display: none;
  }

  &__column2,
  &__column3,
  &__column4 {
    h3 {
      margin-bottom: $space;
      letter-spacing: 0.8px;
      font-weight: 400;
      @include font-size(16px);
    }

    ul {
      padding: 0;
      list-style: none;
      li {
        margin: $space_half 0;
        @include font-size(14px);
      }

      a {
        font-weight: 100;
        text-decoration: none;
        letter-spacing: 0.8px;
        color: $color-claro;
        &:hover {
          color: $color-secundario;
        }
      }

      a.active {
        color: $color-secundario;
      }
    }

    p {
      margin-bottom: $space_x2;
      font-weight: 100;
      text-decoration: none;
      letter-spacing: 0.8px;
      color: $color-claro;
    }

    &-input {
      display: flex;
      align-items: center;
      background-color: $color-claro;
      input {
        padding: $space_half $space;
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
      }

      button {
        width: 40px;
        height: 30px;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: $color-secundario;

        &:hover {
          background-color: get-opacity($color-secundario, 0.7);
        }
      }
    }
  }

  &__column5 {
    display: flex;
    flex-direction: row;

    img {
      margin-right: $space_x3;
      width: 71px;
      height: 97px;
    }

    p {
      font-weight: 100;
      text-decoration: none;
      letter-spacing: 0.8px;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
      color: $color-claro;
      @include font-size(14px);
    }
  }

  &__footer {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p,
    span {
      font-weight: 100;
      text-decoration: none;
      letter-spacing: 0.8px;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
      color: $color-claro;
      @include font-size(12px);
    }

    nav {
      display: none;
    }

    span {
      color: $color-secundario;
    }
  }

  @include media(tablet) {
    padding: $space_x4;
    width: 80%;
    margin: 0 auto;

    &__column4 {
      &-input {
        max-width: 500px;
        input {
          width: 100%;
        }
      }
    }
  }

  @include media(desktop-s) {
    padding: 100px $space_x2;
    grid-gap: $space_x3;
    width: 100%;
    max-width: 100%;
    @include font-size(13px);
    @include grid-template-columns(27% 1fr 1fr 1fr);

    &__column2,
    &__column3,
    &__column4 {
      display: block;
      margin: 0;
    }

    &__column5 {
      margin-left: 0;

      img {
        margin-right: $space;
      }

      p {
        margin: 0;
      }
    }

    &__footer {
      padding-top: 60px;
      grid-column: 1 / span 2;
      text-align: left;
    }
  }

  @include media(desktop-m) {
    padding: 100px $space_x3;
    grid-gap: $space_x2;
    max-width: 90%;
    @include font-size(15px);
    @include grid-template-columns(22% auto auto 20% 20%);

    &__column1 {
      &__info {
        a {
          align-items: start;
          img {
            margin: 6px $space 0 0;
          }
        }
      }
    }

    &__column2,
    &__column3,
    &__column4 {
      display: block;
      margin: 0 $space_x2;
    }

    &__column5 {
      margin-left: $space_x2;
    }

    &__footer {
      grid-column: 1 / span 5;
      flex-direction: row;
      justify-content: space-between;

      nav {
        display: flex;

        .footer-nav-link {
          display: flex;
          justify-content: center;
          align-items: center;
          @include font-size(13px);
          font-weight: 300;
          color: get-opacity($color-claro, 0.5);

          &:not(:last-child) {
            margin-right: 3rem;
          }

          &.active {
            color: $color-secundario;
          }
        }
      }
    }
  }

  @include media(desktop-l) {
    padding: 100px $space_x3;
    grid-gap: $space;
    max-width: 80%;
  }

  @include media(desktop-xxl) {
    max-width: 80%;
  }

  @media (min-width: 2000px) {
    max-width: 1920px;
  }
  @include media(desktop-xxxl) {
    max-width: 90%;
    grid-gap: 1rem;

    &__column5 {
      justify-content: flex-end;

      img {
        margin-right: 1.5rem;
      }
    }
  }
}
