.swal-overlay {
  background-color: get-opacity($color-primario, 0.5);
}

.swal-modal {
  font-family: $Fuente1 !important;
  color: $color-oscuro;
  background-color: $color-claro;
}

.swal-title {
  font-family: $Fuente1 !important;
  color: $color-oscuro;
}

.swal-button {
  font-family: $Fuente1 !important;
  border: none;
  outline: none;
  box-shadow: none;
  color: $color-claro;
  background-color: $color-secundario;
  &:hover {
    background-color: get-opacity($color-secundario, 0.8) !important;

  }
}
