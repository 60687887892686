.prehome__signUp {
    width: 100%;
    height: 100%;
    padding: $space_x6 $space_x4;
    background-color: $color-claro;
    background-image: url(../../images/plane_mb.jpg),
        url(../../images/tower_icon_light.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    @include image-2x-mixed(
        "../../images/plane_mb@2x.jpg",
        "../../images/tower_icon_light@2x.png",
        contain
    );
    @include image-3x-mixed(
        "../../images/plane_mb@3x.jpg",
        "../../images/tower_icon_light@3x.png",
        contain
    );
    position: relative;
    z-index: 2;

    .title {
        width: fit-content;
        color: $color-primario;
        margin-bottom: $space_x5;
        transition: 500ms linear;
        transition-delay: 200ms;
        transform: translateX(-10%);
        opacity: 0;
        @include font-size(32px);
        line-height: 35px;
  
        strong {
          color: $color-secundario;
        }
        &.appear {
          transform: translateX(0);
          opacity: 1;
        }
    }

    .logo {
        display: none;
    }


    //768
    @include media(tablet) {
 
        padding: $space_x15 $space_x11;
        background-image: url(../../images/plane_mb.jpg);
        background-size: cover;
        background-position: center;
        @include image-2x("../../images/plane_mb@2x.jpg", cover);
        @include image-3x("../../images/plane_mb@3x.jpg", cover);
      
    }
  
    //992
    @include media(desktop-s) {

        background-size: cover;
        @include image-2x("../../images/plane_mb@2x.jpg", cover);
        @include image-3x("../../images/plane_mb@3x.jpg", cover);     
    }
  
    //1200
    @include media(desktop-m) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: $space_x10 $space_x5;
        background-image: linear-gradient(to bottom, #fff, transparent 100%),
        url(../../images/plane_dt.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center, top ;
        background-attachment: fixed;
        background-color: transparent;
        @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
            background-image: linear-gradient(to bottom, #fff, transparent 100%),
          url(../../images/plane_dt@2x.jpg);
        }
  
        .title {
            font-weight: 300;
            align-self: center;
            @include font-size(36px);
            line-height: 38px;
    
            strong {
                font-weight: 500;
            }
        }

        .logo {
            align-self: center;
            display: inline-block;
            padding-bottom: 3rem;
        }
    }
  }
  