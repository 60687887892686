@use "sass:math"; // Propiedad de sass para operaciones matemáticas

@mixin image-2x($image, $size) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $size;
  }
}
@mixin image-3x($image, $size) {
  @media (min--moz-device-pixel-ratio: 2.25),
    (-webkit-min-device-pixel-ratio: 2.25),
    (min-device-pixel-ratio: 2.25),
    (min-resolution: 2.25dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $size;
  }
}
@mixin image-2x-mixed($image, $image2, $size) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image), url($image2);
    background-size: $size;
  }
}

@mixin image-3x-mixed($image, $image2, $size) {
  @media (min--moz-device-pixel-ratio: 2.25),
    (-webkit-min-device-pixel-ratio: 2.25),
    (min-device-pixel-ratio: 2.25),
    (min-resolution: 2.25dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image), url($image2);
    background-size: $size;
  }
}

@mixin padding-top-bottom($top, $bottom) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@function calculateRem($size) {
  @if $size!=0 {
    $remSize: math.div($size, 16px);
    @return $remSize * 1rem;
  } @else {
    @return 0;
  }
}
// Pasa font size, altura y letter space y lo pasa a rem
@mixin font-size($size) {
  font-size: calculateRem($size);
  line-height: calculateRem($size) * 1.5;
}

// Opacidad
@function get-opacity($color, $amount) {
  @return rgba($color, $amount);
}

// Call to actions
@mixin buttonSubmit($color1, $color2) {
  color: $color2;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  background-color: transparent;

  span {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $color1;
    transition: all 0.3s;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 2px solid $color1;
    transform: scale(1.2, 1.2);
  }

  &:hover,
  &:focus {
    color: $color1;
    background-color: $color2;
    &::before {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    &::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
