.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-claro;
  background-color: $color-primario;

  h2 {
    margin-top: $space_x5;
    @include font-size(18px);
  }

  img {
    width: 100%;
    max-width: 50%;
    animation: pulse-animation 3s infinite;
  }

  @include media(desktop-s) {
    img {
      max-width: 20%;
    }
  }
}
